import './bootstrap';
import 'flowbite';
import { Dismiss } from 'flowbite';
import { showToast } from './toast';
import { Popover } from 'flowbite';
import 'img-comparison-slider';


function initLazyLoading() {
    const lazyImages = document.querySelectorAll(".lazy:not(.lazy-loaded)");
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                img.src = img.getAttribute("data-src");
                img.classList.remove("lazy");
                img.classList.add("lazy-loaded");
                observer.unobserve(img);
            }
        });
    });
    lazyImages.forEach(img => {
        observer.observe(img);
    });
}
document.addEventListener('DOMContentLoaded', function() {
    const container = document.getElementById('image-filter-container');
    if (!container) return;

    const filterButtons = container.querySelectorAll('.filter-btn');
    const cards = container.querySelectorAll('.grid > div'); // Selektiert alle Karten

    function filterCards(category) {
        cards.forEach(card => {
            const img = card.querySelector('img[data-category], img-comparison-slider img[data-category]');
            if (category === 'all' || (img && img.dataset.category === category)) {
                card.style.display = '';
            } else {
                card.style.display = 'none';
            }
        });
    }

    function updateActiveButton(clickedButton) {
        filterButtons.forEach(button => {
            if (button === clickedButton) {
                // Aktiver Button
                button.classList.remove('text-gray-900', 'border-white', 'hover:border-gray-200', 'bg-white', 'dark:text-white', 'dark:border-gray-900', 'dark:bg-gray-900', 'dark:hover:border-gray-700');
                button.classList.add('text-blue-700', 'border-blue-600', 'bg-white', 'hover:bg-blue-700', 'hover:text-white', 'dark:border-blue-500', 'dark:text-blue-500', 'dark:hover:text-white', 'dark:hover:bg-blue-500', 'dark:bg-gray-900');
            } else {
                // Inaktive Buttons
                button.classList.remove('text-blue-700', 'border-blue-600', 'bg-white', 'hover:bg-blue-700', 'hover:text-white', 'dark:border-blue-500', 'dark:text-blue-500', 'dark:hover:text-white', 'dark:hover:bg-blue-500', 'dark:bg-gray-900');
                button.classList.add('text-gray-900', 'border-white', 'hover:border-gray-200', 'bg-white', 'dark:text-white', 'dark:border-gray-900', 'dark:bg-gray-900', 'dark:hover:border-gray-700');
            }
        });
    }

    filterButtons.forEach(button => {
        button.addEventListener('click', function() {
            const category = this.dataset.filter;
            filterCards(category);
            updateActiveButton(this);
        });
    });

    // Initialisierung mit 'all' Kategorie
    filterCards('all');
    updateActiveButton(filterButtons[0]);
});
document.addEventListener('DOMContentLoaded', function() {
    const images = document.querySelectorAll('.magnify-image');

    images.forEach(img => {
        const container = document.createElement('div');
        container.className = 'magnify-container';
        img.parentNode.insertBefore(container, img);
        container.appendChild(img);

        const glass = document.createElement('div');
        glass.className = 'magnify-glass';
        container.appendChild(glass);

        container.addEventListener('mousemove', magnify);
        container.addEventListener('mouseenter', () => glass.style.display = 'block');
        container.addEventListener('mouseleave', () => glass.style.display = 'none');
    });

    function magnify(event) {
        const glass = event.currentTarget.querySelector('.magnify-glass');
        const img = event.currentTarget.querySelector('img');

        const rect = img.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;

        glass.style.left = `${x - glass.offsetWidth / 2}px`;
        glass.style.top = `${y - glass.offsetHeight / 2}px`;

        const zoomFactor = 2;
        const bgX = x * zoomFactor;
        const bgY = y * zoomFactor;

        glass.style.backgroundImage = `url('${img.src}')`;
        glass.style.backgroundSize = `${img.width * zoomFactor}px ${img.height * zoomFactor}px`;
        glass.style.backgroundPosition = `-${bgX}px -${bgY}px`;
    }
});
export function initializePopovers() {
    const popoverTriggers = document.querySelectorAll('[data-popover-target]');
    popoverTriggers.forEach(trigger => {
        const targetId = trigger.getAttribute('data-popover-target');
        const target = document.getElementById(targetId);
        if (target && !trigger.hasAttribute('data-popover-initialized')) {
            const popover = new Popover(target, trigger);
            trigger.setAttribute('data-popover-initialized', 'true');
        }
    });
}
document.addEventListener('DOMContentLoaded', function() {
    const sections = document.querySelectorAll('section');
    const navLinks = document.querySelectorAll('.nav-link');

    function changeLinkState() {
        let currentSectionIndex = 0;
        const scrollPosition = window.scrollY;

        sections.forEach((section, index) => {
            if (scrollPosition >= section.offsetTop + 500) {
                currentSectionIndex = index;
            }
        });

        navLinks.forEach((link, index) => {
            if (index === currentSectionIndex) {
                link.classList.remove('text-gray-700', 'dark:text-gray-400');
                link.classList.add('text-primary-600', 'dark:text-primary-500');
            } else {
                link.classList.remove('text-primary-600', 'dark:text-primary-500');
                link.classList.add('text-gray-700', 'dark:text-gray-400');
            }
        });
    }

    changeLinkState();
    window.addEventListener('scroll', changeLinkState);

    navLinks.forEach(link => {
        link.addEventListener('click', function(e) {
            e.preventDefault();
            const targetId = this.getAttribute('href').substring(1);
            const targetSection = document.getElementById(targetId);
            window.scrollTo({
                top: targetSection.offsetTop - 50,
                behavior: 'smooth'
            });
        });
    });



});

window.showToast = showToast;
window.initLazyLoading = initLazyLoading;
document.addEventListener('DOMContentLoaded', () => {
    initLazyLoading();
    initializePopovers();
});
